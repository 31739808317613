





























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import 'vue-select/dist/vue-select.css';
import popUpModal from '@/components/form/pop-up-modal.vue';
import { unselectFirstElement,
  duplicateDataItems,
  duplicateDataItemsWithoutSelect,
  recursiveFillData,
  onCurrencyFocus,
  copyObject,
  downloadFile, generateUniqueAmsId, duplicateIndividualPositionsdatenItems } from '@/helper/functions';
import MASCHINENKENNZIFFERS from '@/config/maschinenkennziffer';
import { exportToPDF, saveZusammenfassungPDF } from '@/helper/handle-pdf';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
import apiBackend from '@/service/api-insurance';
import priceStatusIcon from '@/components/form/price-status-icon.vue';
import watchers from '@/watchers/change-data-for-price-request/windenergieanlagen-insurance/calc-tab';
import VersicherungsortRow from '@/views/components/windenergieanlagen-insurance/versicherungsort-row.vue';
import { CustomErrorMessagesInterface, CustomValidator } from '@/helper/custom-validator';

const versicherungsortItem = {
  isSelected: false,
  num: 0,
  strasse: '',
  houseNum: '',
  plz: '',
  ort: '',
  land: '',
  zuers: 0,
  starkregenzone: '',
  naturgefahren2: '',
  naturgefahren3: '',
};
const versicherungsortType = 1;

const hiddenXMLkeys = {
  AMSPK: generateUniqueAmsId(),
  AMSIDNR: generateUniqueAmsId(),
  fillFromAMS: false,
};

const positionsdatenItem = {
  isSelected: false,
  num: 0,
  artDerWEA: '',
  MKZ: '',
  versicherungsort: '' as string | number,
  baujahr: '2023',
  installierteLeistung: '',
  versicherungssumme: 1500000,
  bewertungsjahr: '',
  VSU371: 0,
  deckungsform: '',
  betriebsunterbrechung: {
    open: false,
    progJahresenergieertrag: 0,
    vereinbarteEinspeisevergutung: 0,
    versicherungssumme: 0,
    ausfallziffer: 100,
    haftzeit: 0,
    mitversicherungRuckwirkungsschaden: 'Nein',
    versicherungssummeRW: 0,
    haftzeitRW: '',
    zuschlagPercent: 0,
    zuschlagEUR: 0,
  },
  erweiterteDeckungsinformationen: {
    open: false,
    rabattFurVollwartungsvertrag: 0,
    mitversicherungErdbebenUberschwHochwasser: 'Nein',
    JHE: 0,
    zuschlagIn: 0,
    mitversicherungInnereUnruhen: 0,
    GAPDeckung: 0,
    individuellerNachlass: 0,
    individuellerZuschlag: 0,
  },
  selbstbeteiligung: {
    open: false,
    SB: 0,
    SbPercent: 0,
    SBMin: 0,
    SBMax: 0,
    SBBUPercent: 0,
    SBBUTage: 0,
    SBRWTage: 0,
  },
  keysForXML: copyObject(hiddenXMLkeys),
};
const positionsdatenType = 2;

const maschineAbweichendVomWindenergieanlagentarifItem = {
  isSelected: false,
  num: 0,
  MKZ: '',
  beschreibung: '',
  versicherungssumme: 0,
  bewertungsjahr: '',
  selbstbeteiliung: 0,
  nettobeitragVorRabattenUndZuschlagen: 0,
  keysForXML: copyObject(hiddenXMLkeys),
};

const maschineAbweichendVomWindenergieanlagentarifType = 3;
const erstrisikosummenItem = {
  name: '',
  ERS: 0,
  geanderteERS: 0,
  valueStatic: false,
};

const erstrisikosummenType = 5;

const maxErstrisikosummenCount = 14;

interface PriceItem {
  machineCodeNumber: string;
  codeSFPF: string;
  codeABMGDiebstahl: string;
  assessmentYear: string;
  evaluationPrice: string;
  codeVSu: string;
  codeSachAus: string;
  codeDeductibleVar1: number | string;
  CodeSbBuVar1: string;
  discount1: number | string;
  surcharge3: string;
}

export default Vue.extend({
  name: 'individual-insurance-tarif',
  mixins: [dataChangeMixin],
  components: {
    priceStatusIcon,
    popUpModal,
    VersicherungsortRow,
  },
  data() {
    return {
      modalOpen: false,
      submitedForm: false,
      modalTitle: '',
      modalBody: '',
      name: 'windenergieanlagen-insurance-tarif',
      key: 'WINDENERGIEANLAGEN_INSURANCE',
      backUrl: 'windenergieanlagen-insurance',
      electricityVolumeValues: [
        {
          value: '',
          label: '',
        },
        {
          value: 'value 1',
          label: 'value 1',
        },
        {
          value: 'value 2',
          label: 'value 2',
        },
      ],
      versicherungssummeWEA: 0,
      versicherungssummeBU: 0,
      zahlungsweise: '',
      versicherungsteuer: '19%',
      Nettoisierung: 'Nein',
      Faktorenstand: new Date().getFullYear(),
      versicherungsortItems: [
        {
          ...versicherungsortItem,
        },
      ],
      ByversicherungsortType: versicherungsortType,
      positionsdatenItems: [
        {
          ...positionsdatenItem,
        },
      ],
      BypositionsdatenType: positionsdatenType,
      maschineAbweichendVomWindenergieanlagentarifItems: [
        {
          ...maschineAbweichendVomWindenergieanlagentarifItem,
        },
      ],
      ByMaschineAbweichendVomWindenergieanlagentarifType: maschineAbweichendVomWindenergieanlagentarifType,
      maschineAbweichendVomWindenergieanlagentarif: {
        MKZ: '',
        beschreibung: '',
        versicherungssumme: '',
        bewertungsjahr: '',
        selbstbeteiliung: '',
        nettobeitragBUVorRabattenUndZuschlagen: null,
      },
      tariffPremiumCalculation: {
        SBTarif: {
          name: 'Tarif SB',
          priceKey: 'tarif',
        },
        SBVariante1: {
          name: 'SB Variante 1',
          priceKey: 'variant1',
        },
        SBVariante2: {
          name: 'SB Variante 2',
          priceKey: 'variant2',
        },
        SBVariante3: {
          name: 'SB Variante 3',
          priceKey: 'variant3',
        },
      },
      selbstbeteiligungsvariantenEinspeisevergutung: {
        SBTarif: {
          name: 'SB- Tarif',
          SB: 5000,
          SbPercent: 0,
          SBMin: 0,
          SbMax: 0,
          SBBUPercent: null,
          SBBUTage: 7,
          BUHaftzeit: 6,
          SBRWTage: '',
          sentPriceRequest: false,
        },
        items: {
          SBVariante1: {
            name: 'SB-Variante 1',
            SB: 0,
            SbPercent: 0,
            SBMin: 0,
            SbMax: 0,
            SBBUPercent: null,
            SBBUTage: 7,
            BUHaftzeit: 6,
            SBRWTage: '',
            sentPriceRequest: false,
          },
          SBVariante2: {
            name: 'SB-Variante 2',
            SB: 0,
            SbPercent: 0,
            SBMin: 0,
            SbMax: 0,
            SBBUPercent: null,
            SBBUTage: 7,
            BUHaftzeit: 6,
            SBRWTage: '',
            sentPriceRequest: false,
          },
          SBVariante3: {
            name: 'SB-Variante 3',
            SB: 0,
            SbPercent: null,
            SBMin: 0,
            SbMax: 0,
            SBBUPercent: null,
            SBBUTage: 7,
            BUHaftzeit: 6,
            SBRWTage: '',
            sentPriceRequest: false,
          },
        },
      },
      deckungserweiterungen: {
        mitversicherungInnereUnruhen: {
          variante1: 'Nein',
          variante2: 'Nein',
          variante3: 'Nein',
        },
        mitversicherungRegressverzicht: {
          variante1: 'Nein',
          variante2: 'Nein',
          variante3: 'Nein',
        },
        mitversicherungSamtlicheNaturgefahren: {
          select: 'Nein',
          summ: 0,
          zuschlag: 0,
        },
        mitversicherungDerHoherhaftungInsBU: {
          electricityVolume: {
            value: 0,
            label: '',
          },
          zuschlag: 0,
        },
      },
      erstrisikosummenItems: [
        {
          name: 'Aufräumungs-, Bergungs-, Dekontaminations- und Entsorgungskosten',
          ERS: 25000,
          geanderteERS: 0,
          valueStatic: true,
        },
        {
          name: 'Dekontaminations- und Entsorgungskosten für Erdreich',
          ERS: 25000,
          geanderteERS: 0,
          valueStatic: true,
        },
        {
          name: 'Bewegungs- und Schutzkosten',
          ERS: 25000,
          geanderteERS: 0,
          valueStatic: true,
        },
        {
          name: 'Luftfrachtkosten',
          ERS: 25000,
          geanderteERS: 0,
          valueStatic: true,
        },
        {
          name: 'Erd-, Pflaster-, Maurer- und Stemmarbeiten',
          ERS: 25000,
          geanderteERS: 0,
          valueStatic: true,
        },
        {
          name: 'Kosten für Daten',
          ERS: 10000,
          geanderteERS: 0,
          valueStatic: true,
        },
        {
          name: 'Kosten für Schäden an Gebäuden',
          ERS: 10000,
          geanderteERS: 0,
          valueStatic: true,
        },
        {
          name: 'Miet- und Leihkosten oder Kosten für Finanzierungsraten',
          ERS: 10000,
          geanderteERS: 0,
          valueStatic: true,
        },
        {
          name: 'Zuwegekosten',
          ERS: 10000,
          geanderteERS: 0,
          valueStatic: true,
        },
      ],
      ByerstrisikosummenType: erstrisikosummenType,
      deductibleVariantsCssClasses: {
        SBVariante1: 'table-variant-1',
        SBVariante2: 'table-variant-2',
        SBVariante3: 'table-variant-3',
      },
      priceItemKey: '',
      MASCHINENKENNZIFFERS,
      refImportFileName: 'excelImport',
      priceResponseData: {
        SBTarif: {
        },
        SBVariante1: {
        },
        SBVariante2: {
        },
        SBVariante3: {
        },
      },
      errorMessages: {
        factorLevel: '',
      },
      customValidator: new CustomValidator(),
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'priceData', 'id', 'insuranceFormsApiData'],
  watch: {
    ...watchers,
    'priceData.tarif.priceResponse': function (newVal, oldVal) {
      this.fillPriceResponse(newVal, oldVal);
    },
    'priceData.variant1.priceResponse': function (newVal, oldVal) {
      this.fillPriceResponse(newVal, oldVal);
    },
    'priceData.variant2.priceResponse': function (newVal, oldVal) {
      this.fillPriceResponse(newVal, oldVal);
    },
    'priceData.variant3.priceResponse': function (newVal, oldVal) {
      this.fillPriceResponse(newVal, oldVal);
    },
  },
  methods: {
    onCurrencyFocus,
    downloadInsurancePlacesTemplate() {
      apiBackend.downloadInsurancePlaces().then((response) => {
        downloadFile(response.data, 'Einlesen von Versicherungsorten.xlsx');
      });
    },
    goToBackUrl() {
      this.onSubmit(false, false);
      this.$emit('go-back-tab', this.backUrl, 'insuranceForms', 1);
    },
    exportPlacesData() {
      const data = this.versicherungsortItems.map((itm) => ({
        street: itm.strasse,
        code: `${itm.plz}`,
        city: itm.ort,
        land: itm.land,
      }));

      apiBackend.exportPlacesData(data).then((response) => {
        downloadFile(response.data, 'Versicherungsort-Windenergieanlagen.xlsx');
      });
    },
    changedArtDerWEA(i: number) {
      this.positionsdatenItems[i].MKZ = this.findArtDerWEABy(this.positionsdatenItems[i].artDerWEA, 'name', 'value');
    },
    changedMKZ(i: number) {
      this.positionsdatenItems[i].artDerWEA = this.findArtDerWEABy(this.positionsdatenItems[i].MKZ, 'value', 'name');
    },
    findArtDerWEABy(value: any, searchFieldName: string, returnFieldName: string): any {
      const foundItem = this.MASCHINENKENNZIFFERS.find((itm) => itm[searchFieldName] === value);

      if (foundItem) {
        return foundItem[returnFieldName] || null;
      }

      return null;
    },
    deleteErstrisikosummenItem(key) {
      this.erstrisikosummenItems.splice(key, 1);
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    exportToPDF() {
      const filename = `Zusammenfassung-Windenergieanlagen-Tarif-${this.id}.pdf`;
      exportToPDF.call(this, filename);
    },
    savePdf() {
      this.customValidator.validate();

      if (this.customValidator.getTotalValidationResult()) {
        saveZusammenfassungPDF.call(this, this.id, 'saveTarifPDF', this.onSubmit);
      }
    },
    deleteRows(type: number) {
      let items;
      switch (type) {
        case this.ByversicherungsortType:
          unselectFirstElement(this.versicherungsortItems);

          items = this.versicherungsortItems.filter((item) => !item.isSelected);
          this.versicherungsortItems = items;

          this.updatePositionsdatenItems();
          break;
        case this.BypositionsdatenType:
          unselectFirstElement(this.positionsdatenItems);

          items = this.positionsdatenItems.filter((item) => !item.isSelected);
          this.positionsdatenItems = items;
          break;
        case this.ByMaschineAbweichendVomWindenergieanlagentarifType:
          unselectFirstElement(this.maschineAbweichendVomWindenergieanlagentarifItems);

          items = this.maschineAbweichendVomWindenergieanlagentarifItems.filter((item) => !item.isSelected);
          this.maschineAbweichendVomWindenergieanlagentarifItems = items;
          break;
        default:
          console.log('Wrong type');
      }
    },
    updatePositionsdatenItems() {
      const firstValidNumber = this.versicherungsortItems.length > 0 ? 1 : 1;

      this.positionsdatenItems = this.positionsdatenItems.map((posItem) => {
        const isValid = this.versicherungsortItems.length >= posItem.versicherungsort && posItem.versicherungsort !== 0;

        if (!isValid) {
          return {
            ...posItem,
            versicherungsort: firstValidNumber,
          };
        }
        return posItem;
      });
    },
    dublicateRow(fillData: boolean, type: number) {
      switch (type) {
        case this.ByversicherungsortType:
          duplicateDataItems(versicherungsortItem, this.versicherungsortItems, fillData);
          break;
        case this.BypositionsdatenType:
          duplicateIndividualPositionsdatenItems(positionsdatenItem, this.positionsdatenItems, fillData);
          break;
        case this.ByMaschineAbweichendVomWindenergieanlagentarifType:
          duplicateIndividualPositionsdatenItems(maschineAbweichendVomWindenergieanlagentarifItem, this.maschineAbweichendVomWindenergieanlagentarifItems, fillData);
          break;
        case this.ByerstrisikosummenType:
          if (this.erstrisikosummenItems.length < maxErstrisikosummenCount) {
            duplicateDataItemsWithoutSelect(erstrisikosummenItem, this.erstrisikosummenItems);
          }
          break;
        default:
          console.log('Wrong type');
      }
    },
    createAdditionalPositionsdatenItems(count: number) {
      for (let i = 1; i < count; i += 1) {
        this.dublicateRow(false, this.BypositionsdatenType);
      }
    },
    fillPositionsdatenItems(basicData: any) {
      this.positionsdatenItems.forEach((element: any, index) => {
        if (basicData.MASCHINENKENNZIFFER) {
          this.positionsdatenItems[index].artDerWEA = basicData.MASCHINENKENNZIFFER.name ?? '';
          this.positionsdatenItems[index].MKZ = basicData.MASCHINENKENNZIFFER.value ?? '';
        }
        this.positionsdatenItems[index].deckungsform = basicData.deckungsform ?? '';
        this.positionsdatenItems[index].erweiterteDeckungsinformationen.rabattFurVollwartungsvertrag = basicData.nachlassFurVollwartungsvertrag ?? 0;
        this.positionsdatenItems[index].versicherungssumme = basicData.versicherungssummeJeAnlage ?? 0;
        this.positionsdatenItems[index].betriebsunterbrechung.versicherungssumme = basicData.versicherungssummeBUJeAnlage ?? '';
      });
    },
    fillSelbstbeteiligungsvariantenEinspeisevergutung(basicData: any) {
      this.selbstbeteiligungsvariantenEinspeisevergutung.items.SBVariante1.SB = basicData.SBMaschine ?? 0;
      this.selbstbeteiligungsvariantenEinspeisevergutung.items.SBVariante1.SBBUTage = basicData.SBBU ? basicData.SBBU : 7;
      this.selbstbeteiligungsvariantenEinspeisevergutung.SBTarif.BUHaftzeit = basicData.haftzeitBU ? basicData.haftzeitBU : 6;
      this.selbstbeteiligungsvariantenEinspeisevergutung.items.SBVariante1.BUHaftzeit = basicData.haftzeitBU ? basicData.haftzeitBU : 6;
      this.selbstbeteiligungsvariantenEinspeisevergutung.items.SBVariante2.BUHaftzeit = basicData.haftzeitBU ? basicData.haftzeitBU : 6;
      this.selbstbeteiligungsvariantenEinspeisevergutung.items.SBVariante3.BUHaftzeit = basicData.haftzeitBU ? basicData.haftzeitBU : 6;
    },
    setValues(insuranceData: any) {
      this.versicherungssummeWEA = this.sumWea;
      this.versicherungssummeBU = this.sumBu;
      this.zahlungsweise = insuranceData.zahlungsweise;
      this.versicherungsteuer = insuranceData.versicherungsteuer;
      this.Nettoisierung = insuranceData.Nettoisierung;
      this.Faktorenstand = insuranceData.Faktorenstand;
      this.versicherungsortItems = insuranceData.versicherungsortItems;
      this.positionsdatenItems = insuranceData.positionsdatenItems;
      this.maschineAbweichendVomWindenergieanlagentarif = insuranceData.maschineAbweichendVomWindenergieanlagentarif;
      this.maschineAbweichendVomWindenergieanlagentarifItems = insuranceData.maschineAbweichendVomWindenergieanlagentarifItems;
      this.selbstbeteiligungsvariantenEinspeisevergutung = insuranceData.selbstbeteiligungsvariantenEinspeisevergutung;
      this.deckungserweiterungen = insuranceData.deckungserweiterungen;
      this.erstrisikosummenItems = insuranceData.erstrisikosummenItems;
      this.priceResponseData = insuranceData.priceResponseData;

      if (!this.deckungserweiterungen?.mitversicherungDerHoherhaftungInsBU?.electricityVolume?.value) {
        this.deckungserweiterungen.mitversicherungDerHoherhaftungInsBU.electricityVolume = {
          value: 0,
          label: '',
        };
      }
    },
    onSubmit(goNextPage = true, changeTab = true) {
      const data = {
        versicherungssummeWEA: this.sumWea,
        versicherungssummeBU: this.sumBu,
        zahlungsweise: this.zahlungsweise,
        versicherungsteuer: this.versicherungsteuer,
        Nettoisierung: this.Nettoisierung,
        Faktorenstand: this.Faktorenstand,
        versicherungsortItems: this.versicherungsortItems,
        positionsdatenItems: this.positionsdatenItems,
        maschineAbweichendVomWindenergieanlagentarif: this.maschineAbweichendVomWindenergieanlagentarif,
        maschineAbweichendVomWindenergieanlagentarifItems: this.maschineAbweichendVomWindenergieanlagentarifItems,
        selbstbeteiligungsvariantenEinspeisevergutung: this.selbstbeteiligungsvariantenEinspeisevergutung,
        deckungserweiterungen: this.deckungserweiterungen,
        erstrisikosummenItems: this.erstrisikosummenItems,
        priceResponseData: this.priceResponseData,
      };
      this.$emit('spreadsheet-data-change', data, this.key, goNextPage, changeTab);

      this.submitedForm = true;
    },
    checkingMaschineAbweichendVomWindenergieanlagentarifItemFilled(item: any) {
      return (item.versicherungssumme && item.versicherungssumme > 0);
    },
    savePriceResponse(key: string, PriceResponse: any) {
      this.priceResponseData[key] = PriceResponse;
    },
    fillPriceResponse(newVal, oldVal) {
      this.savePriceResponse(this.priceItemKey, newVal.Result);
      if (newVal.Result.Messages !== null) {
        let messages = '';

        newVal.Result.Messages.forEach((element: any, index) => {
          messages = `${messages + element} `;
        });
        this.openModal('errors', messages);
      }
      let currentIndex = 0;
      this.maschineAbweichendVomWindenergieanlagentarifItems.forEach((element: any, index) => {
        if (this.checkingMaschineAbweichendVomWindenergieanlagentarifItemFilled(element)) {
          // eslint-disable-next-line no-param-reassign
          element.nettobeitragVorRabattenUndZuschlagen = Number(newVal.Result.Maschinen[currentIndex]['Prämie_SB_Var1_Maschine']);
          currentIndex += 1;
        }
      });
      this.positionsdatenItems.forEach((element: any, index) => {
        // eslint-disable-next-line no-param-reassign
        element.VSU371 = Number((Number(element.versicherungssumme) / Number(newVal.Result.Maschinen[currentIndex].Summenfaktor)).toFixed(0));
        currentIndex += 1;
      });
    },
    setPrice(key: string) {
      this.priceItemKey = key;
      const franchiseOptionsVariant = this.getSelbstbeteiligungsvariantenEinspeisevergutungByKey(key);
      const priceData = this.makePriceDataRequest(franchiseOptionsVariant, key);

      this.$emit('price-card-data', priceData, key);
    },
    getSelbstbeteiligungsvariantenEinspeisevergutungByKey(key: string): any {
      if (key === 'SBTarif') {
        return this.selbstbeteiligungsvariantenEinspeisevergutung?.SBTarif;
      }

      return this.selbstbeteiligungsvariantenEinspeisevergutung?.items[key];
    },
    makePriceDataRequest(selbstbeteiligungsvariantenEinspeisevergutung: any, key: string) {
      const machinery: PriceItem[] = [];
      const priceApiData = [];
      this.maschineAbweichendVomWindenergieanlagentarifItems.forEach((element: any, index) => {
        if (this.checkingMaschineAbweichendVomWindenergieanlagentarifItemFilled(element)) {
          const machineryItem = {
            type: 'maschine',
            machineCodeNumber: element.MKZ,
            codeSFPF: '6',
            codeABMGDiebstahl: '',
            assessmentYear: element.bewertungsjahr,
            evaluationPrice: element.versicherungssumme,
            codeVSu: '',
            codeSachAus: '',
            codeDeductibleVar1: element.selbstbeteiliung,
            CodeSbBuVar1: selbstbeteiligungsvariantenEinspeisevergutung.SBBUTage,
            discount1: 0,
            surcharge3: '0',
          };
          machinery.push(machineryItem);
        }
      });
      this.positionsdatenItems.forEach((element: any, index) => {
        const machineryItem = {
          type: 'positionsdaten',
          machineCodeNumber: element.MKZ,
          codeSFPF: (key === 'SBVariante3') ? element.betriebsunterbrechung.haftzeit : selbstbeteiligungsvariantenEinspeisevergutung.BUHaftzeit,
          codeABMGDiebstahl: '',
          assessmentYear: element.bewertungsjahr,
          evaluationPrice: element.versicherungssumme,
          codeVSu: '',
          codeSachAus: '',
          codeDeductibleVar1: (key === 'SBVariante3') ? element.selbstbeteiligung.SB : selbstbeteiligungsvariantenEinspeisevergutung.SB,
          CodeSbBuVar1: (key === 'SBVariante3') ? element.selbstbeteiligung.SBBUTage : selbstbeteiligungsvariantenEinspeisevergutung.SBBUTage,
          discount1: 0,
          surcharge3: '0',
        };
        machinery.push(machineryItem);
      });
      const priceData = {
        insuranceKey: this.key,
        machinery,
        awardOverview: {
          codePaymentMethod: this.zahlungsweise,
          bonusYear: this.Faktorenstand,
        },
        erstrisikosummenItems: this.erstrisikosummenItems,
      };
      return priceData;
    },
    // test function, remove after test
    getPriceRequest(selbstbeteiligungsvariantenEinspeisevergutung: any, key: string) {
      const priceData = this.makePriceDataRequest(selbstbeteiligungsvariantenEinspeisevergutung, key);

      this.$emit('price-card-request-test', priceData);
    },
    fillVersicherungsortItems(versicherungsort: any) {
      this.versicherungsortItems.forEach((element: any, index) => {
        this.versicherungsortItems[index].strasse = versicherungsort.Strabe;
        this.versicherungsortItems[index].plz = versicherungsort.PLZ;
        this.versicherungsortItems[index].ort = versicherungsort.Ort;
        this.versicherungsortItems[index].zuers = versicherungsort.ZursZone;
        this.versicherungsortItems[index].starkregenzone = versicherungsort.Starkregenzone;
        this.versicherungsortItems[index].houseNum = versicherungsort.Nr;
      });
    },
    runDublicateRow() {
      while (this.showPlusBtn) {
        this.dublicateRow(false, this.ByerstrisikosummenType);
      }
    },
    fillApiValuesData() {
      if (this.insuranceFormsApiData && this.insuranceFormsApiData.spreadsheetData) {
        this.runDublicateRow();
        this.zahlungsweise = this.insuranceFormsApiData.spreadsheetData.zahlungsweise;
        this.Nettoisierung = this.insuranceFormsApiData.spreadsheetData.Nettoisierung;
        this.Faktorenstand = this.insuranceFormsApiData.spreadsheetData.Faktorenstand;
        recursiveFillData(this.positionsdatenItems, this.insuranceFormsApiData.spreadsheetData.positionsdatenItems);
        recursiveFillData(this.erstrisikosummenItems, this.insuranceFormsApiData.spreadsheetData.erstrisikosummenItems);
        recursiveFillData(this.deckungserweiterungen, this.insuranceFormsApiData.spreadsheetData.deckungserweiterungen);
        this.deckungserweiterungen.mitversicherungDerHoherhaftungInsBU.electricityVolume = this.insuranceFormsApiData.spreadsheetData.deckungserweiterungen.mitversicherungDerHoherhaftungInsBU.electricityVolume;
        for (let i = 1; i < this.insuranceFormsApiData.spreadsheetData.versicherungsortItems?.length; i += 1) {
          duplicateDataItems(versicherungsortItem, this.versicherungsortItems, false);
        }
        recursiveFillData(this.versicherungsortItems, this.insuranceFormsApiData.spreadsheetData.versicherungsortItems);
        recursiveFillData(this.maschineAbweichendVomWindenergieanlagentarifItems, this.insuranceFormsApiData.spreadsheetData.maschineAbweichendVomWindenergieanlagentarifItems);
      }
    },
    chooseImportFile() {
      this.excelImportRef.click();
    },
    uploadFile(element) {
      const file = element.target.files[0];

      if (file) {
        apiBackend.uploadFile(`${this.id}/import-places-excel`, 'import', file).then((response: any) => {
          this.importPositionsdatenFromExcel(response.data);
          this.excelImportRef.value = null;
        }).catch((e) => {
          console.error(`Error - ${e}`);
        });
      }
    },
    importPositionsdatenFromExcel(dataItems: any[]) {
      const firstElementEmpty = this.versicherungsortItems.length === 1 &&
        !this.versicherungsortItems[0].strasse &&
        !this.versicherungsortItems[0].plz &&
        !this.versicherungsortItems[0].ort &&
        !this.versicherungsortItems[0].land;

      dataItems.forEach((item, i) => {
        const fillFirst = firstElementEmpty && i === 0;

        const newItem = fillFirst ? this.versicherungsortItems[0] : copyObject(versicherungsortItem);

        newItem.strasse = item.street;
        newItem.plz = item.postCode;
        newItem.ort = item.place;
        newItem.land = item.land;

        if (!fillFirst) {
          this.versicherungsortItems.push(newItem);
        }
      });
    },
    getPriceKey(variantPrice: string) {
      let variant = 'tarif';
      if (variantPrice === 'SBTarif') {
        variant = 'tarif';
      } else if (variantPrice === 'SBVariante1') {
        variant = 'variant1';
      } else if (variantPrice === 'SBVariante2') {
        variant = 'variant2';
      } else if (variantPrice === 'SBVariante3') {
        variant = 'variant3';
      }
      return variant;
    },
    initValidators() {
      this.customValidator.setValidators([
        {
          htmlId: 'factorLevel',
          validate: () => this.Faktorenstand <= (new Date()).getFullYear(),
          message: 'Der Wert sollte nicht größer sein als der Wert des aktuellen Jahres',
        },
      ]);
    },
  },
  computed: {
    priceRequestTarif(): any {
      return this.makePriceDataRequest(this.selbstbeteiligungsvariantenEinspeisevergutung.SBTarif, 'SBTarif');
    },
    priceRequestVariant1(): any {
      return this.makePriceDataRequest(this.selbstbeteiligungsvariantenEinspeisevergutung.items.SBVariante1, 'SBVariante1');
    },
    priceRequestVariant2(): any {
      return this.makePriceDataRequest(this.selbstbeteiligungsvariantenEinspeisevergutung.items.SBVariante2, 'SBVariante2');
    },
    priceRequestVariant3(): any {
      return this.makePriceDataRequest(this.selbstbeteiligungsvariantenEinspeisevergutung.items.SBVariante3, 'SBVariante3');
    },
    showPlusBtn(): boolean {
      return (this.erstrisikosummenItems.length < 14);
    },
    showMinusBtn(): boolean {
      return (this.erstrisikosummenItems.length > 10);
    },
    countVersicherungsortItems(): number {
      return this.versicherungsortItems.length;
    },
    sumWea(): number {
      let sum = 0;

      this.positionsdatenItems.forEach((itm) => {
        sum += +itm.versicherungssumme;
      });

      return sum;
    },
    sumBu(): number {
      let sum = 0;

      this.positionsdatenItems.forEach((itm) => {
        sum += +itm.betriebsunterbrechung.versicherungssumme;
      });

      return sum;
    },
    disabledBusinessInterruptionItems(): any[] {
      const result: any[] = [];

      this.positionsdatenItems.forEach((itm) => {
        result.push({
          disabled: itm.betriebsunterbrechung.mitversicherungRuckwirkungsschaden === 'Nein',
        });
      });

      return result;
    },
    disabledExtendedCoverageInformationItems(): any[] {
      const result: any[] = [];

      this.positionsdatenItems.forEach((itm) => {
        result.push({
          disabled: itm.erweiterteDeckungsinformationen.mitversicherungErdbebenUberschwHochwasser === 'Nein',
        });
      });

      return result;
    },
    disabledDeckungserweiterungenItems(): boolean {
      return this.deckungserweiterungen.mitversicherungSamtlicheNaturgefahren.select === 'Nein';
    },
    excelImportRef(): any {
      return this.$refs[this.refImportFileName];
    },
    isApiUser(): boolean {
      return this.$store.getters['authModule/isApiUser'];
    },
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
  },
  created() {
    this.customValidator.initErrorMessages(this.errorMessages as CustomErrorMessagesInterface);
    this.initValidators();
  },
  mounted() {
    (this as any).resetWatcherActivated();
    this.$emit('on-key-change', this.key);
    if (this.insuranceData && this.insuranceData.spreadsheetData) {
      this.setValues(this.insuranceData.spreadsheetData);
    } else {
      setTimeout(() => {
        if (this.insuranceData && this.insuranceData.basicData) {
          this.createAdditionalPositionsdatenItems(this.insuranceData.basicData.anzahlDerAnlagen ?? 1);
          this.fillPositionsdatenItems(this.insuranceData.basicData);
          this.fillSelbstbeteiligungsvariantenEinspeisevergutung(this.insuranceData.basicData);
          this.fillVersicherungsortItems(this.insuranceData.basicData.versicherungsort);
          this.fillApiValuesData();
        }
      }, 500);
    }
    (this as any).startWatcherActivated();
  },
  beforeDestroy() {
    if (!this.isPCUser && !this.submitedForm) {
      this.onSubmit(false, false);
    }
  },
});
